import 'core-js/stable';

import jquery from 'jquery';
import moment from 'moment';
import 'react-dates/initialize';

import Routing from './utils/routing';
import Translator from './utils/translation';

// make jQuery available globally
const $ = jquery;
global.$ = $;
global.jQuery = $;

// make moment available globally
global.moment = moment;
global.moment.locale('de');

global.Routing = Routing;
global.Translator = Translator;
